<template>
  <div class="iss-main contactFrequency">
    <!-- 未来30天   -->

    <div class="list-wrapper">
      <div class="reminderTitle">
        <div class="reminderT">
          <div class="titleT">生日</div>
        </div>
      </div>
      <template
        v-for="(scourceItem, scourceIndex) in dataSource"
        :key="scourceIndex"
      >
        <div class="yearRow">
          <div class="mark"></div>
          <span v-if="scourceIndex == 0">{{ new Date().getFullYear() }}年</span>
          <span v-else>{{ new Date().getFullYear() + 1 }}年</span>
        </div>
        <div v-for="(obj, key) in scourceItem" :key="key">
          <template v-if="obj.length > 0">
            <div class="birthdayTitle" @click="handleOpenList(key)">
              <div class="nextThirtyDays" v-if="key === 'recently'">
                未来30天
              </div>
              <div class="nextThirtyDays" v-if="key === 'January'">
                1月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'February'">
                2月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'March'">
                3月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'April'">
                4月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'May'">
                5月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'June'">
                6月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'July'">
                7月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'August'">
                8月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'September'">
                9月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'October'">
                10月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'November'">
                11月（{{ obj.length }}人）
              </div>
              <div class="nextThirtyDays" v-if="key === 'December'">
                12月（{{ obj.length }}人）
              </div>
              <DownOutlined class="downOutlined" />
            </div>

            <div v-if="obj.length > 0">
              <a-list
                v-if="showList[key] && key === showKey[key]"
                item-layout="horizontal"
                :loading="loading"
                :data-source="obj"
                :key="key"
              >
                <template #renderItem="{ item }">
                  <a-list-item :class="{ showBac: item.isShow }">
                    <a-list-item-meta>
                      <template #title>
                        <CaretDownOutlined
                          class="normal-car-icon"
                          @click="enter(obj, item)"
                          style="font-size: 12px; margin-right: 8px"
                          v-if="
                            item.contactTodoMatterVoList &&
                            item.contactTodoMatterVoList?.length > 0
                          "
                        />
                        <!-- <img
                        v-else-if="!item.isShow && key === 'recently'"
                        class="list-dot-icon"
                        src="@/assets/images/reminder/listdot.png"
                        @click="enter(obj, item)"
                        style="margin-right: 8px"
                      />
                      <img
                        v-else-if="key === 'recently'"
                        class="list-dot-icon"
                        src="@/assets/images/reminder/listdot-active.png"
                        @click="enter(obj, item)"
                        style="margin-right: 8px"
                      /> -->
                        <div class="titleName">
                          <a-avatar
                            v-if="item.avatar"
                            :src="setAvatar(item.avatar)"
                            @click="handleGoToContact(item)"
                            class="headerUrl"
                          />
                          <div
                            v-else
                            @click="handleGoToContact(item)"
                            class="headerText"
                          >
                            <div v-if="item.name">
                              {{ item.name[0] }}
                            </div>
                          </div>
                          <div class="listName">
                            {{ item.name }}
                          </div>

                          <div class="avatarClass">
                            <div class="textMl mr-46">
                              {{ item.company }}
                            </div>
                          </div>

                          <div class="avatarClass">
                            <div class="textMl mr-8">
                              {{ item.position }}
                            </div>
                          </div>
                        </div>
                      </template>

                      <template #description>
                        <!--          todo 头像放置处     -->
                        <div class="description">
                          <div class="total">
                            <!--                   缺图标   -->

                            <!-- {{ item.todoCompleteSum ? item.todoCompleteSum : 0 }}/ -->
                            <!-- {{ item.todoCount ? item.todoCount : 0 }} -->
                          </div>

                          <div class="pink">
                            <!--  纯日期时间展示，鼠标移入后隐藏   -->
                            <div class="statistic-item">
                              <img src="@/assets/images/reminder/birthday.png" alt="" />
                              <div class="item-title">
                                {{ handleReminderTime(item) }}
                              </div>
                            </div>
                            <!-- v-if="key === 'recently'" -->
                            <div class="ribbon">
                              <!--                       新增生日    -->
                              <a-tooltip placement="top">
                                <template #title>
                                  <span>创建待办</span>
                                </template>
                                <div class="gouGou" @click="handleAdd(item)">
                                  <PlusOutlined class="gou" />
                                </div>
                              </a-tooltip>
                            </div>
                          </div>
                        </div>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                  <!--       嵌套数据渲染    -->
                  <div
                    class="ifMous"
                    v-if="item.isShow"
                    :class="{ showBac: item.isShow }"
                  >
                    <div v-if="item.contactTodoMatterVoList" class="ifClass">
                      <!--           todo 鼠标移入联系人 需要展示该联系人下的主待办和子级待办     -->
                      <div
                        v-for="(
                          listItem, index
                        ) in item.contactTodoMatterVoList"
                        :key="listItem.id"
                        :class="{
                          showBor:
                            item.contactTodoMatterVoList.length - 1 === index,
                        }"
                      >
                        <div class="birthdayZhu">
                          <CaretDownOutlined
                            class="sp-car-icon"
                            :class="{ showCac: listItem.showBir }"
                            @click="
                              enterBirthdayC(
                                item.contactTodoMatterVoList,
                                listItem
                              )
                            "
                            style="font-size: 12px; margin-right: 8px"
                            v-if="
                              listItem.subItemsList &&
                              listItem.subItemsList.length > 0
                            "
                          />

                          <!-- <img
                          v-else-if="!listItem.isShow"
                          class="list-dot-icon"
                          src="@/assets/images/reminder/listdot.png"
                          @click="
                            enterBirthdayC(
                              item.contactTodoMatterVoList,
                              listItem
                            )
                          "
                          style="margin-right: 8px"
                        />
                        <img
                          v-else
                          class="list-dot-icon"
                          src="@/assets/images/reminder/listdot-active.png"
                          @click="
                            enterBirthdayC(
                              item.contactTodoMatterVoList,
                              listItem
                            )
                          "
                          style="margin-right: 8px"
                        /> -->
                          <div
                            class="listNameC birContent"
                            :class="{ showLine: listItem.completed }"
                            @click="handleName(listItem)"
                          >
                            {{ listItem.content }}
                          </div>
                          <div
                            style="width: 20px"
                            v-if="
                              !listItem.subItemsList ||
                              listItem.subItemsList.length == 0
                            "
                          ></div>
                          <!--    v-if="listItem.personnelType === 1"   -->
                          <div
                            class="flexCalssC"
                            v-if="listItem.personnelType === 1"
                          >
                            <div class="personnelVoListCalss">
                              <div
                                class="avatarClass"
                                v-for="itemT in listItem?.personnelVoList.slice(
                                  0,
                                 6
                                )"
                                :key="itemT.id"
                              >
                                <a-avatar
                                  v-if="itemT.avatar"
                                  :src="setAvatar(itemT.avatar)"
                                  @click="handleGoToContact(itemT)"
                                  class="headerUrl"
                                />
                                <div
                                  v-else
                                  @click="handleGoToContact(itemT)"
                                  class="headerText headerTextC"
                                >
                                  <div v-if="itemT.name">
                                    {{ itemT.name[0] }}
                                  </div>
                                </div>
                                <div
                                  class="textMl"
                                  @click="handleGoToContact(itemT)"
                                  v-if="listItem?.personnelVoList.length === 1"
                                >
                                  {{ itemT.name }}
                                </div>
                              </div>
                            </div>

                            <!--               todo 生日主待办操作    -->
                            <div class="descriptionC">
                              <!--          操作按钮放置处    -->
                              <div class="actions">
                                <div class="statisticMun">
                                  <div class="item-title">
                                    <img
                                      src="@/assets/images/reminder/fenbu.png"
                                      alt=""
                                      class="fenbuImg"
                                    />
                                    <!--                      todoCompleteSum 待办事项完成数量/ subItemsList 待办事项总数     -->
                                    {{
                                      item.todoCompleteSum
                                        ? item.todoCompleteSum
                                        : 0
                                    }}/{{ todoCount ? todoCount : 0 }}
                                  </div>
                                </div>

                                <!--  纯日期时间展示，鼠标移出后隐藏  -->
                                <div class="statistic-item">
                                  <div class="item-title">
                                    {{
                                      handleReminderTime(listItem?.reminderTime)
                                    }}
                                  </div>
                                </div>
                                <!-- v-if="listItem.showBir" -->
                                <div class="ribbon">
                                  <!--                生日父待办，编辑显示生日新增弹窗           -->
                                  <a-tooltip placement="top">
                                    <template #title>
                                      <span>编辑待办</span>
                                    </template>
                                    <div
                                      class="dropDown"
                                      @click="
                                        handleOpenAddBirthday(listItem, 'edit')
                                      "
                                    >
                                      <img
                                        src="@/assets/images/reminder/editT.png"
                                        alt=""
                                        class="editImg"
                                      />
                                    </div>
                                  </a-tooltip>
                                  <a-tooltip placement="top">
                                    <template #title>
                                      <span>删除待办</span>
                                    </template>
                                    <!--  生日主待办删除 -->
                                    <div
                                      class="timeTime"
                                      @click="handleDelete(listItem)"
                                    >
                                      <img
                                        src="@/assets/images/reminder/delT.png"
                                        alt=""
                                        class="deleteImg"
                                      />
                                    </div>
                                  </a-tooltip>
                                  <a-tooltip placement="top">
                                    <template #title>
                                      <span>全部完成</span>
                                    </template>
                                    <!--  生日主待办完成 -->
                                    <div
                                      class="gouGou"
                                      @click="handleGou(listItem)"
                                    >
                                      <CheckOutlined class="gou" />
                                    </div>
                                  </a-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flexCalssC" v-else>
                            <div class="personnelVoListCalss">
                              <div
                                class="avatarClass"
                                v-for="itemT in listItem?.personnelVoList.slice(
                                  0,
                                  6
                                )"
                                :key="itemT.id"
                              >
                                <a-avatar
                                  v-if="itemT.avatar"
                                  :src="setAvatar(itemT.avatar)"
                                  @click="handleGoToContact(itemT)"
                                  class="headerUrl"
                                />
                                <div
                                  v-else
                                  @click="handleGoToContact(itemT)"
                                  class="headerText"
                                >
                                  <div v-if="itemT.name">
                                    {{ itemT.name[0] }}
                                  </div>
                                </div>
                                <div
                                  class="textMl"
                                  @click="handleGoToContact(itemT)"
                                  v-if="listItem?.personnelVoList.length === 1"
                                >
                                  {{ itemT.name }}
                                </div>
                              </div>
                            </div>

                            <!--               todo 生日主待办操作    -->
                            <div class="descriptionC">
                              <!--          操作按钮放置处    -->
                              <div class="actions">
                                <div class="statisticMun">
                                  <div class="item-title">
                                    <img
                                      src="@/assets/images/reminder/fenbu.png"
                                      alt=""
                                      class="fenbuImg"
                                    />
                                    <!--                      todoCompleteSum 待办事项完成数量/ subItemsList 待办事项总数     -->
                                    {{
                                      item.todoCompleteSum
                                        ? item.todoCompleteSum
                                        : 0
                                    }}/{{
                                      item.subItemsList?.length
                                        ? item.subItemsList?.length
                                        : 0
                                    }}
                                  </div>
                                </div>

                                <!--  纯日期时间展示，鼠标移出后隐藏  -->
                                <div class="statistic-item">
                                  <div class="item-title">
                                    {{
                                      handleReminderTime(listItem?.reminderTime)
                                    }}
                                  </div>
                                </div>
                                <!-- v-if="listItem.showBir" -->
                                <div class="ribbon">
                                  <!--                生日父待办，编辑显示生日新增弹窗           -->
                                  <a-tooltip placement="top">
                                    <template #title>
                                      <span>编辑待办</span>
                                    </template>
                                    <div
                                      class="dropDown"
                                      @click="
                                        handleOpenAddBirthday(listItem, 'edit')
                                      "
                                    >
                                      <img
                                        src="@/assets/images/reminder/editT.png"
                                        alt=""
                                        class="editImg"
                                      />
                                    </div>
                                  </a-tooltip>
                                  <!--                生日主待办删除             -->
                                  <a-tooltip placement="top">
                                    <template #title>
                                      <span>删除待办</span>
                                    </template>
                                    <div
                                      class="timeTime"
                                      @click="handleDelete(listItem)"
                                    >
                                      <img
                                        src="@/assets/images/reminder/delT.png"
                                        alt=""
                                        class="deleteImg"
                                      />
                                    </div>
                                  </a-tooltip>
                                  <!--                   生日主待办完成       -->
                                  <a-tooltip placement="top">
                                    <template #title>
                                      <span>全部完成</span>
                                    </template>
                                    <div
                                      class="gouGou"
                                      @click="handleGou(listItem)"
                                    >
                                      <CheckOutlined class="gou" />
                                    </div>
                                  </a-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--      todo   生日的子待办放置处     -->
                        <div
                          v-if="listItem.showBir"
                          :class="{ showBac: listItem.isShow }"
                          class="showBir"
                        >
                          <div
                            class="listItemC"
                            v-for="(itemBir, index) in listItem.subItemsList"
                            :key="itemBir.id"
                            :class="{
                              showBor:
                                listItem.subItemsList.length - 1 === index,
                            }"
                          >
                            <!--                  生日子级待办完成  主待办id  listItem   -->
                            <!-- <div class="ml-10">
                            <a-checkbox
                              v-model:value="itemBir.id"
                              v-model:checked="itemBir.completed"
                              @change="handleCheck(listItem, itemBir)"
                            />
                          </div> -->

                            <!--               -->
                            <div
                              class="listName listItemName"
                              :class="{ showLine: itemBir.completed }"
                            >
                              {{ itemBir.subContent }}
                            </div>
                            <!--           头像、描述、操作放置处     -->
                            <div class="description">
                              <!--          操作按钮放置处    -->
                              <div class="actions">
                                <!--  纯日期时间展示，鼠标移出后隐藏  -->
                                <div class="statistic-item">
                                  <div class="item-title">
                                    {{ handleReminderTime(itemBir) }}
                                  </div>
                                </div>
                                <!-- v-if="item.isShow" -->
                                <div class="ribbon">
                                  <a-tooltip
                                    placement="top"
                                    v-if="itemBir.completed"
                                  >
                                    <template #title>
                                      <span>撤回完成</span>
                                    </template>
                                    <div
                                      class="dropDown"
                                      @click="
                                        handleCheck(listItem, itemBir, false)
                                      "
                                    >
                                      <RedoOutlined class="editImg" />
                                    </div>
                                  </a-tooltip>
                                  <a-tooltip placement="top" v-else>
                                    <template #title>
                                      <span>完成待办</span>
                                    </template>
                                    <div
                                      class="gouGou"
                                      @click="
                                        handleCheck(listItem, itemBir, true)
                                      "
                                    >
                                      <CheckOutlined class="gou" />
                                    </div>
                                  </a-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </a-list>
            </div>

            <!-- <div v-else>
            <div
              v-if="!(showList[key] && key === showKey[key])"
              class="objNone"
            >
              暂无联系人生日
            </div>
          </div> -->
          </template>
        </div>
      </template>
    </div>

    <add-birthday
      v-model:visible="editPage"
      :init="activeItem"
      @fnOk="handleEditPage"
    />

    <todo-details
      v-model:visible="opneTodoDetails"
      :init="todoDetailsItem"
      @fnOk="handleTodoDetails"
    />
  </div>
</template>

<script>
import {
  createVNode,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  watch,
  // getCurrentInstance,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  List,
  // message,
  Avatar,
  Modal,
  message,
  Checkbox,
  Tooltip,
} from 'ant-design-vue';
import {
  PlusOutlined,
  CheckOutlined,
  DownOutlined,
  CaretDownOutlined,
  RedoOutlined,
} from '@ant-design/icons-vue';
import todayApi from '@/api/today';
// import { local } from '@/utils/storage';
import { setAvatar } from '@/utils';
import addBirthday from '@/views/today/birthday/addBirthday';
import * as icons from '@ant-design/icons-vue';
import todoDetails from '@/views/today/homePage/components/todoDetails';

export default {
  components: {
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    AAvatar: Avatar,
    DownOutlined,
    CheckOutlined,
    PlusOutlined,
    addBirthday,
    CaretDownOutlined,
    // ACheckbox: Checkbox,
    ATooltip: Tooltip,
    RedoOutlined,
    todoDetails,
  },
  props: {
    type: String,
    search: Object,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    // const { proxy } = getCurrentInstance();
    const state = reactive({
      loading: false,
      showEditDelete: false,
      editPage: false,
      activeItem: {},
      dataSource: {},

      opneTodoDetails: false,
      todoDetailsItem: {},

      showTime: false,
      content: '',
      searchType: null,
      enterId: '',
      leaveId: '',
      // 数组的key
      dataKey: '',
      showKey: {
        recently: '',
        January: '',
        February: '',
        March: '',
        April: '',
        May: '',
        June: '',
        July: '',
        August: '',
        September: '',
        October: '',
        November: '',
        December: '',
      },
      showList: {
        January: false,
        February: false,
        March: false,
        April: false,
        May: false,
        June: false,
        July: false,
        August: false,
        September: false,
        October: false,
        November: false,
        December: false,
      },
    });
    const form = reactive({
      time: '',
    });

    const initList = () => {
      state.loading = true;
      // 获取当前日期
      let currentDate = new Date();
      // 获取当前月份（注意月份是从0开始计数，所以需要加1）
      let currentMonth = currentDate.getMonth() + 1;
      let month = parseInt(currentMonth);

      // 未来三十天
      // 获取今天的日期
      let today = new Date();
      // 格式化今天的日期为 "YYYY-MM-DD 00:00:00" 的字符串，并添加到数组中
      let todayFormatted = today.toISOString().split('T')[0] + ' 00:00:00';

      let thirtiethDay = new Date(today);
      thirtiethDay.setDate(today.getDate() + 29);
      let formattedThirtiethDay =
        thirtiethDay.toISOString().split('T')[0] + ' 23:59:59';

      todayApi
        .getContactByAllBirthday(route.path, {
          userId: store.state.account.user.id,
          month: month,
          startTime: todayFormatted,
          endTime: formattedThirtiethDay,
        })
        .then(res => {
          // console.log('shengri', state.dataSource);
          let dataSource = res;
          let dataSource2 = {};

          Reflect.deleteProperty(res, 'recently');

          let monthList = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];

          for (let i = 0; i < currentMonth - 1; i++) {
            Object.assign(dataSource2, {
              [monthList[i]]: dataSource[monthList[i]],
            });
            Reflect.deleteProperty(dataSource, monthList[i]);
          }

          // console.log(dataSource);
          // console.log(dataSource2);

          let arr = [];
          arr.push(dataSource);
          arr.push(dataSource2);

          state.dataSource = arr;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    watch([props.search, () => props.type], () => initList());
    initList();

    onMounted(() => {});

    // // 在页面注销前，将点击事件给移除
    onBeforeUnmount(() => {});

    const rules = {
      time: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
    };

    return {
      // pagination,
      ...toRefs(state),
      setAvatar,
      rules,
      form,

      handleName(item) {
        console.log('handleName', item);
        Object.assign(state.todoDetailsItem, item);
        // 跟联系人详情页面同一个弹窗
        state.opneTodoDetails = true;
      },

      handleTodoDetails(value) {
        state.opneTodoDetails = value;
        // 刷新列表
        initList(1, state.size);
      },

      handleGoToContact(record) {
        router.push(`/contacts/${record.id}`);
      },

      // 鼠标移入生日主待办展示子级
      enterBirthdayC: (list, item) => {
        list.map(i => {
          if (i.id === item.id) {
            // i.showBir = true;
            i.showBir = !i.showBir;
          }
        });
      },
      //
      leaveBirthdayC: (list, item) => {
        list.map(i => {
          if (i.id === item.id) {
            i.showBir = false;
          }
        });
      },

      enter: (list, item) => {
        // state.enterId = item.id
        list.map(i => {
          if (i.id === item.id) {
            // i.isShow = true;
            i.isShow = !i.isShow;
          }
        });
        // console.log('触发了 onmouseenter 鼠标移入事件！', item);
      },

      leave: (list, item) => {
        // state.enterId = item.id
        list.map(i => {
          if (i.id === item.id) {
            i.isShow = false;
          }
        });
        // console.log('触发了mouseleave 鼠标移出事件！', item);
      },

      handleOpenList: type => {
        // 每一个月份有自己状态
        // console.log('type56666', type);

        if (type) {
          state.dataKey = type;
          state.showKey[type] = type;
          state.showList[type] = !state.showList[type];
        }
        // console.log('type', state.showKey, state.showList);
      },

      handleCheck(item, listItem, completeState) {
        console.log('handleCheck5555', item, listItem);
        // 调取子待办完成接口
        todayApi
          .markTodoSubItemsCompleted('', {
            userId: store.state.account.user.id,
            todoId: item.id,
            id: listItem.id,
            completed: completeState,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              // initList(1, state.size);
              todayApi
                .queryTodoSubItem('', {
                  id: listItem.id,
                })
                .then(res => {
                  listItem.subContent = res.subContent;
                  listItem.completed = res.completed;
                });
            }
          });
      },

      handleTime(item) {
        state.showTime = !state.showTime;
        // console.log('handleTime', item);
      },
      handleAdd(item) {
        // console.log('新增生日', item);
        state.editPage = true;
        state.activeItem = {
          ...item,
          type: 'add',
        };
      },
      handleGou(item) {
        // console.log('待办完成');
        // 调取主待办完成接口
        todayApi
          .markTodoCompleted('', {
            userId: store.state.account.user.id,
            ...item,
            id: item.id,
            completed: true,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              initList();
            }
          });
      },
      handleOpenAddBirthday(item, type) {
        state.editPage = true;
        state.activeItem = {
          ...item,
          type: type,
        };
      },
      handleDelete(item) {
        Modal.confirm({
          title: `您确定要删除此待办吗？所有相关数据都将被删除，并且此操作无法撤消。`,
          icon: createVNode(icons['ExclamationCircleOutlined']),
          okType: '',
          okText: '确认',
          onOk: () => {
            todayApi
              .delteMatter('resources:delete', { ids: [item.id] })
              .then(res => {
                if (res) {
                  message.success('操作成功');
                  initList();
                }
              });
          },
        });
      },
      gotoAll() {},
      handleEditPage(value) {
        state.editPage = value;
        initList();
      },
      handleReminderTime(item) {
        if (item?.birthday) {
          // 创建一个日期对象
          let dateObject = new Date(item?.birthday);
          // 提取月份、日期、小时和分钟
          let month = dateObject.getMonth() + 1; // 月份从0开始，所以需要加1
          let day = dateObject.getDate();
          // let hours = dateObject.getHours();
          // let minutes = dateObject.getMinutes();
          // 格式化输出
          let formattedTime = `${month}月${day}日`;
          return formattedTime;
        } else {
          return '';
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  //padding-top: 40px;
  //padding-left: 32px;
  //padding-right: 32px;
  padding: 40px 72px;
}

.list-wrapper {
  //max-height: calc(100vh - 110px);
  background: #ffffff;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  border-radius: 0 8px 8px 0;

  .list-title {
    padding: 14px 20px;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0 8px 0 0;
  }
  .move {
    cursor: pointer;
  }
  .banner {
    height: 80px;
    width: 142px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .title {
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    vertical-align: middle;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  .address {
    margin-top: 4px;
    min-height: 22px;
    img {
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      margin-right: 4px;
    }
  }
  // :deep(.ant-spin-nested-loading) {
  //   max-height: ;
  // }
  :deep(.ant-list-pagination) {
    margin: 20px 20px 20px 0;
    font-size: 12px;
    .ant-pagination-options-quick-jumper input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
    }
    a {
      font-size: 14px;
    }
  }
  :deep(.ant-checkbox-group) {
    border-radius: 0 0 8px 0;
  }
}

.statistic-item {
  //width: 103px;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    object-fit: cover;
  }
  .item-title {
    margin-right: 10px;
    color: @text-color-secondary;
  }
  .item-count {
    text-align: right;
    font-size: 24px;
    color: @primary-color;
  }
}
// :deep(.ant-list-items) {
//   min-height: calc(100vh - 273px);
//   height: calc(100vh - 309px);
//   overflow-y: auto;
// }
.userName {
  padding-left: 32px;
}
.list-time {
  padding-top: 4px;
  img {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 4px;
  }
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.list-wrapper /deep/ .ant-list-item-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-wrapper /deep/.ant-list-item-meta-title {
  width: 60%;
}
.list-wrapper /deep/ .ant-list-item-meta-description {
  width: 40%;
}

.listName {
  //width: 500px;
  //width: 350px;
  margin-right: 24px;
  //white-space: nowrap; /*超出的空白区域不换行*/
  //overflow: hidden; /*超出隐藏*/
  //text-overflow: ellipsis; /*文本超出显示省略号*/
  margin-left: 10px;
  cursor: pointer;
  max-width: 51%;
  min-width: 10%;
  width: 90px;
  flex-shrink: 0;
}
.pink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //width: 340px;
  //width: 400px;
  width: 70%;
  margin-right: 10px;
}
.description {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //width: 500px;
  //width: 671px;
  width: 100%;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
}
// .total {
// }
.showBac {
  background: #fffbf6;
  //border-left: 2px solid #ff7b00;

  /deep/ .normal-car-icon {
    transform: rotate(180deg);
    // transition: transform 0.3s;
  }
}
/deep/ .showCac {
  transform: rotate(180deg);
  // transition: transform 0.3s;
}

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 26px 0px;

  .listItemName {
    width: 524px;
    //margin-left: 0px;
  }
}
//.searchClass {
//  display: flex;
//  justify-content: flex-end;
//  align-items: center;
//  margin: 25px 0px 0px;
//}
.iconClass {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffc85b;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
}

.headerTextC {
  background: #ffc85b;
  margin-right: 2px;
}

.reminderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .reminderT {
    width: 80%;
    .titleT {
      font-size: 24px;
      font-weight: 500;
    }
  }
}
.birthdayTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border-bottom: 0.5px solid #eeeeee;
  background: #fffbf6;
  .nextThirtyDays {
    width: 80%;
    font-size: 14px;
    font-weight: 700;
    color: #ff7b00;
  }

  .downOutlined {
    width: 20%;
    text-align: right;
    color: #272636;
  }
}
.titleName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .headerText {
    min-width: 26px;
  }
}
.list-wrapper /deep/ ul.ant-list-items {
  max-height: 534px;
  overflow: auto;
  //padding-left: 8px;
  //padding-right: 8px;
}
.gouGou {
  border-radius: 4px;
  background: #ffffff;
  width: 24px;
  height: 24px;
  cursor: pointer;
  .gou {
    margin-left: 4px;
  }
}

.avatarClass {
  // min-width: 40%;
  // width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.objNone {
  font-size: 14px;
  color: #666666;
  border-bottom: 0.5px solid #eeeeee;
  padding: 14px 0px;
  padding-left: 8px;
}
// todo 生日嵌套样式
.ifMous {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .ifClass {
    width: 100%;
  }
  .birthdayZhu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 26px 0px;

    .listNameC {
      width: 50%;
      padding-right: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
      cursor: pointer;
    }

    .flexCalssC {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: 50%;
      .avatarClass {
        //width: 400px;
        //width: 294px;
        margin-right: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        //width: 60%;
        .headerUrl {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .textMl {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.9);
        }
        .headerTextC {
          background: #ffc85b;
          margin-right: 2px;
        }
      }

      .descriptionC {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* width: 100%; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 40%;
        padding-right: 10px;
        .actions {
          margin-left: auto;
          display: flex;
          justify-content: flex-end;
          //align-items: center;
          align-items: baseline;
          cursor: pointer;
          width: 30%;
          //padding-right: 10px;
          .statisticMun {
            margin-right: 12px;
            .item-title {
              .fenbuImg {
                width: 14px;
                height: 14px;
                margin-right: 4px;
              }
            }
          }
          .ribbon {
            width: 96px;
            margin-right: 8px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            .dropDown {
              position: relative;
              border-radius: 4px;
              background: #ffffff;
              width: 24px;
              height: 24px;
              .editImg {
                margin-left: 4px;
                width: 16px;
                height: 16px;
              }
              .editDelete {
                background: #fff;
                padding: 8px 16px;
                border-radius: 4px;
                position: absolute;
                top: 26px;
                left: -18px;
                cursor: pointer;
                width: 104px;
                color: #3d3d3d;
                z-index: 2;
                .edit {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .editImg {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }
                }
                .delete {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 16px;
                  .deleteImg {
                    width: 16px;
                    height: 16px;
                    color: #ff8f1f;
                    margin-right: 10px;
                  }
                }
              }
            }

            .timeTime {
              border-radius: 4px;
              background: #ffffff;
              width: 24px;
              height: 24px;
              margin: 0px 8px;
              .deleteImg {
                //margin: 0px 12px;
                margin-left: 4px;
                width: 16px;
                height: 16px;
              }
            }

            .gouGou {
              border-radius: 4px;
              background: #ffffff;
              width: 24px;
              height: 24px;
              .gou {
                margin-left: 4px;
              }
            }
            .timeModal {
              position: absolute;
              top: 30px;
              left: -130px;
            }
          }
        }
      }
    }
  }
  .personnelVoListCalss {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //width: 50%;
  }
}

/deep/ .ant-list-item-meta-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.showBir {
  .listItemC {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //padding: 26px 0px;
    padding-bottom: 26px;

    // .listItemName {
    //   width: 524px;
    //   margin-left: 0px;
    // }
    .showLine {
      text-decoration: line-through;
      color: rgba(102, 102, 102, 0.9);
    }
  }
  .showBor {
    border-bottom: 1px solid #eeeeee;
  }
}

.list-dot-icon {
  width: 14px;
  height: 14px;
  margin-top: 2px;
}

.yearRow {
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  .mark {
    width: 4px;
    height: 16px;
    background: #999999;
  }

  span {
    margin-left: 8px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }
}
</style>
